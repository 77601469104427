<!--
markdown编辑组件

1. 通过@input进行数据的绑定
2. 自动的调整高度
-->
<template>
  <div>
    <div class="markdown-editer" ref="editorRef">
      <!--    <textarea ref="textareaRef" @focus="handleFocus(true)" @blur="handleFocus(false)" :value="value" @input="handleChange"></textarea>-->

    </div>
    <div id="imagePreviewDiv"></div>
    <button id="copyButton" style="display: none;">复制</button>
  </div>

</template>

<script>
import { basicSetup, EditorView } from "codemirror"
import { indentWithTab } from "@codemirror/commands"
import { EditorSelection, StateEffect, StateField } from '@codemirror/state';
import { keymap, Decoration, WidgetType, ViewPlugin } from '@codemirror/view'
import { markdown } from "@codemirror/lang-markdown"
import { languages } from "@codemirror/language-data"
import typechoMixin from "@/mixin/typechoMixin"

// 定义一个状态效应，用于添加装饰
const addImagePreviewEffect = StateEffect.define({
  map: (value, mapping) => mapping.map(value)
});

// 定义一个状态字段来存储装饰
const imagePreviewField = StateField.define({
  create() {
    return Decoration.none;
  },
  update(decorations, tr) {
    // 应用状态效应以更新装饰
    if (tr.effects.some(e => e.is(addImagePreviewEffect))) {
      const effects = tr.effects.filter(e => e.is(addImagePreviewEffect));
      const newDecorations = effects.map(e => e.value).flat();
      return Decoration.set(newDecorations);
    }
    return decorations.map(tr.changes);
  },
  provide: f => EditorView.decorations.from(f)
});

// 创建一个插件来监听文档的变化，并相应地更新装饰
const imagePreviewPlugin = ViewPlugin.fromClass(class {
  constructor(view) {
    this.updateDecorations(view);
  }

  update(update) {

    // 检查文档是否有变化
    if (!update.docChanged) return;

    let decorationsNeedUpdate = false;

    // 获取当前文档的全部内容
    const docContent = update.view.state.doc.toString();

    // 使用正则表达式检查文档内容是否包含图片链接
    const re = /!\[.*?\]\((.*?)\)/g;
    let match;
    while ((match = re.exec(docContent)) !== null) {
      // 检查变更区域内是否有匹配项
      update.changes.iterChanges((fromA, toA, fromB, toB) => {
        if ((fromB <= match.index && match.index < toB) || (fromB < match.index + match[0].length && match.index + match[0].length <= toB)) {
          decorationsNeedUpdate = true;
        }
      });
    }

    // 如果检测到需要更新的装饰，更新装饰
    if (decorationsNeedUpdate) {
      this.updateDecorations(update.view);
    }
  }

  updateDecorations(view) {
    const decorations = [];
    const re = /!\[.*?\]\((.*?)\)/g;
    const doc = view.state.doc.toString();
    let match;

    while ((match = re.exec(doc)) !== null) {
      const imageUrl = match[1];
      const deco = Decoration.widget({
        widget: new ImageWidget(imageUrl),
        side: 1 // 放置在匹配项的右侧
      });
      decorations.push(deco.range(match.index));
    }
    setTimeout(() => {
      view.dispatch({
        effects: addImagePreviewEffect.of(decorations)
      });
    }, 0)

  }
}, {
  eventHandlers: {
    update: (update) => this.update(update)
  }
});

class ImageWidget extends WidgetType {
  constructor(src) {
    super();
    this.src = src;
  }

  toDOM() {
    const img = document.createElement('img');
    img.src = this.src;
    // img.style.width = '90%';
    img.style.height = '400px';
    img.style.display = 'block'; // 确保图片在新行显示
    img.style.margin = '10px 0'; // 添加一些间距
    // img.onload = () => {
    //   const containerWidth = img.parentElement.offsetWidth; // 获取容器宽度
    //   const imgNaturalWidth = img.naturalWidth;
    //   const imgNaturalHeight = img.naturalHeight;
    //   const aspectRatio = imgNaturalHeight / imgNaturalWidth; // 计算原始宽高比
    //   const targetWidth = containerWidth * 0.9; // 目标宽度为容器宽度的90%
    //   const targetHeight = targetWidth * aspectRatio; // 根据宽高比计算目标高度
    //   img.style.height = `${targetHeight}px`; // 设置图片高度
    // };
    return img;
  }

  ignoreEvent() {
    return false;
  }
}

export default {
  name: 'index',
  mixins: [typechoMixin],
  props: {
    value: {
      type: String,
      default: ''
    },
    noteId: {
      type: String,
      default: ''
    }
  },
  watch: {
    testValue() {
      this.$nextTick(() => {
        // this.changeHeight()
        alert("改变了")
      })
    }
  },
  data() {
    return {
      editHeight: 0,
      testValue: '',
      view: null,
    }
  },
  mounted () {
    // this.changeHeight()
    const textarea = this.$refs.editorRef
    const that = this

    const view = new EditorView({
      doc: that.value,
      extensions: [
        basicSetup,
        imagePreviewPlugin,
        imagePreviewField,
        markdown({ codeLanguages: languages }),
        EditorView.updateListener.of((v) => {
          // this.value = v.state.doc.toString()
          // alert("改变了")
          this.$emit('input', v.state.doc.toString())
        }),
        EditorView.lineWrapping, // 换行
        keymap.of([indentWithTab]), // tab键缩进
        EditorView.theme({
          // ".cm-content": { color: "darkorange" },
          ".cm-content": { fontFamily: "Microsoft YaHei", fontSize: "14px" },
          // "&.cm-focused .cm-content": { color: "orange" }
          ".cm-gutters": { background: "transparent", display: this.$refs.editorRef.clientWidth > 400 ? null : 'none' },
          "&.cm-focused": { outline: "none" },
        }),
        EditorView.updateListener.of((view) => {
          // console.log(view)
        }),
      ],
      parent: textarea
    })
    this.$nextTick(() => {
      view.focus()
    })
    this.view = view

    // 监听鼠标移动事件
    // view.dom.addEventListener('mousemove', (e) => {
    //   const coords = { x: e.clientX, y: e.clientY }
    //   const pos = view.posAtCoords(coords)
    //
    //   if (pos) {
    //     const line = view.state.doc.lineAt(pos)
    //     // console.log(line)
    //     const imageUrl = this.extractImageUrl(view.state.doc, line.text);
    //     if (imageUrl) {
    //       // console.log(imageUrl)
    //       // this.showImagePreview(imageUrl, e.clientX, e.clientY)
    //     } else {
    //       this.hideImagePreview()
    //     }
    //   }
    // })
    // view.dom.addEventListener('mouseleave', () => {
    //   this.hideImagePreview()
    // })

    // 上传图片到服务器的函数

    view.dom.addEventListener("paste", (event) => {
      const items = (event.clipboardData || event.originalEvent.clipboardData).items;
      for (const item of items) {
        if (item.type.indexOf("image") === 0) {
          const file = item.getAsFile();
          const timestamp = Date.now();

          const newFileName = `image_${timestamp}.${file.name.split('.').pop()}`; // 基于时间戳的新文件名

          const imageUrl = `/usr/uploads/${newFileName}`; // 构造基于时间戳的URL
          // 先插入URL
          const transaction = view.state.update({
            changes: { from: view.state.selection.main.from, insert: `![Image](${imageUrl})` },
          });
          view.dispatch(transaction);
          if (this.noteId === '') {
            this.$parent.$parent.saveNote()
          }
          this.$nextTick(() => {
            const intervalId = setInterval(() => {
              if (this.noteId !== '') {
                this.uploadImage(file, this.noteId, newFileName);
                clearInterval(intervalId);
              }
            }, 2000); // 每 1 秒检查一次
          });
          // 然后上传图片
        }
      }
    });

    view.dom.addEventListener('mousemove', (e) => {
      const coords = { x: e.clientX, y: e.clientY };
      const pos = view.posAtCoords(coords);

      if (pos) {
        this.handleCopyButton(view, pos, e.clientX, e.clientY);
      }
    });
    // 获取焦点触发

    // view.dispatch({ // 文章内容的插入
    //   changes: { from: 0, insert: that.value }
    // })

    // view.state.update({
    //   annotations: that.testValue
    // })

    // 当编辑器内容发生变化时，把内容传递给父组件

    // view.state.changes({
    //   filter: (change) => {
    //     that.$emit('input', view.state.doc.toString())
    //   }
    // })

  },
  methods: {
    insertAtCurrentPosition(content, offsetLines = 0) {
      if (this.view && this.view instanceof EditorView) {
        // 获取当前的选择范围
        const { from, to } = this.view.state.selection.main;
        // 获取当前光标的位置
        const currentPos = this.view.state.selection.main.head;
        // 创建一个事务来替换当前选择的文本或在光标位置插入文本
        this.view.dispatch({
          changes: { from: from, to: to, insert: content },
        });

        // 重新计算新光标位置
        const linesBeforeInsertion = this.view.state.doc.lineAt(currentPos).number;
        const newPosition = this.view.state.doc.line(linesBeforeInsertion + offsetLines - 1).from;

        // 创建新的选择范围
        const newSelection = EditorSelection.create([EditorSelection.range(newPosition, newPosition)]);

        // 设置新的光标位置
        this.view.dispatch({ selection: newSelection });

        // 将焦点回到编辑器
        this.view.focus();
      }
    },
    findCompleteCodeBlock(doc, lineNo) {
      let startLine = lineNo;
      let endLine = lineNo;
      let line;

      // 向上查找代码块的开始
      while (startLine > 0) {
        line = doc.line(startLine).text;
        if (line.startsWith('```')) break;
        startLine--;
      }

      // 向下查找代码块的结束
      while (endLine < doc.lines) {
        line = doc.line(endLine).text;
        if (line.startsWith('```') && endLine !== lineNo) break;
        endLine++;
      }

      // 提取不包含 ``` 的代码块内容
      // 注意：startLine + 1 和 endLine - 1 用于跳过开始和结束的 ``` 行
      return doc.sliceString(doc.line(startLine + 1).from, doc.line(endLine).from);
    },
    findStartOfCodeBlock(doc, lineNo) {
      let startLine = lineNo;

      // 向上查找代码块的开始
      while (startLine > 0) {
        const line = doc.line(startLine).text;
        if (line.startsWith('```')) break;
        startLine--;
      }

      return startLine;
    },
    handleCopyButton(view, pos) {
      const line = view.state.doc.lineAt(pos);
      if (/^```[\S]+/.test(line.text)) {
        // 查找代码块起始行
        const startLine = this.findStartOfCodeBlock(view.state.doc, line.number);

        // 获取代码块起始行的位置
        const startLinePos = view.coordsAtPos(view.state.doc.line(startLine).from);

        if (startLinePos) {
          const copyButton = document.getElementById('copyButton');
          copyButton.style.display = 'block';
          copyButton.style.left = `${startLinePos.right + 200}px`;
          copyButton.style.top = `${startLinePos.top}px`;

          // 设置复制按钮的点击事件
          const codeBlockText = this.findCompleteCodeBlock(view.state.doc, line.number);
          copyButton.onclick = () => {
            navigator.clipboard.writeText(codeBlockText);
          };
        }
      } else {
        document.getElementById('copyButton').style.display = 'none';
      }
    },
    hideImagePreview() {
      // console.log('Attempting to hide image preview');
      const previewDiv = document.getElementById('imagePreviewDiv');
      if (previewDiv) {
        previewDiv.style.display = 'none';
      }
    },
    extractImageUrls(doc) {
      const imageRefRegex = /\[(\d+)\]:\s*(.+)/g;
      const imageUrls = {};
      let match;

      for (let i = 0; i < doc.lines; i++) {
        const lineText = doc.line(i + 1).text;
        while ((match = imageRefRegex.exec(lineText)) !== null) {
          imageUrls[match[1]] = match[2];
        }
      }

      return imageUrls;
    },
    extractImageUrl(doc, lineText) {
      // 直接的图片链接格式：![alt text](url)
      const directImageRegex = /!\[.*?\]\((.*?)\)/;
      const directMatch = directImageRegex.exec(lineText);
      if (directMatch) {
        return directMatch[1];
      }

      // 带引用标记的图片链接格式：![alt text][id]
      const refImageRegex = /!\[.*?\]\[(\d+)\]/;
      const refMatch = refImageRegex.exec(lineText);
      if (refMatch) {
        const imageUrls = this.extractImageUrls(doc);
        return imageUrls[refMatch[1]];
      }

      return null
    },
    showImagePreview(imageUrl, x, y) {
      const previewDiv = document.getElementById('imagePreviewDiv');
      previewDiv.innerHTML = `<img src="${imageUrl}" alt="Image Preview" style="max-width: 100%; max-height: 550px;" />`; // 设置最大宽高
      previewDiv.style.display = 'block'; // 先显示元素以获取其尺寸

      // 获取图片预览的尺寸
      const previewRect = previewDiv.getBoundingClientRect();

      // 计算预览是否会超出窗口底部
      const overflowBottom = (y + previewRect.height) > window.innerHeight;

      if (overflowBottom) {
        // 如果会超出窗口底部，向上调整位置
        previewDiv.style.top = `${y - previewRect.height - 20}px`;
      } else {
        // 否则，正常放置
        previewDiv.style.top = `${y + 20}px`;
      }

      previewDiv.style.left = `${x}px`;
    },

    changeHeight() {
      console.log("changeHeight")
      this.$nextTick(() => {
        const textarea = this.$refs.textareaRef
        const nowHeight = textarea.scrollHeight
        textarea.style.height = '80px'
        if (nowHeight < 80) {
          textarea.style.height = 80 + 'px'
        } else {
          textarea.style.height = nowHeight + 'px'
        }
        this.editHeight = textarea.style.height
      })
    },
    handleChange(event) {
      this.$emit('input', event.target.value)
    },
    handleFocus(isFocus) {
      this.$emit('focus', isFocus)
    }
  }
}
</script>

<style lang="scss" scoped>
#imagePreviewDiv {
  position: fixed;  /* 固定定位 */
  z-index: 1000;    /* 高层级 */
  //max-width: 800px; /* 最大宽度 */
  max-height: 600px;/* 最大高度 */
  padding: 10px;    /* 内边距 */
  background-color: rgba(255, 255, 255, 0.9); /* 背景色 */
  border: 1px solid #ccc; /* 边框 */
  border-radius: 8px; /* 圆角 */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* 阴影效果 */
  display: none;     /* 默认不显示 */
}
#copyButton {
  position: fixed;
  z-index: 1000;
  padding: 4px 6px;
  font-size: 14px;
  background-color: #f8f9fa; /* 浅灰色背景 */
  color: #333; /* 深色文字 */
  border: 1px solid #ddd; /* 浅色边框 */
  border-radius: 4px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* 轻微的阴影 */
  cursor: pointer;
  transition: all 0.2s ease;
}

#copyButton:hover {
  background-color: #e2e6ea; /* 悬停时的背景颜色稍微深一些 */
  border-color: #ccc; /* 悬停时边框颜色 */
}

#copyButton:active {
  background-color: #d6d8db; /* 按钮被点击时的背景颜色 */
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1) inset; /* 内部阴影以增加按下的效果 */
  transform: translateY(1px); /* 轻微下移以模仿按下的效果 */
}

.markdown-editer{
  height: 100%;
  position: relative;
  .red-background {
    background-color: red;
  }
  textarea{
    position: relative; // absolute会脱离标准流
    font-size: 14px;
    color: #333;
    font-family: "Microsoft YaHei",serif;
    word-break: break-word;
    width: calc(100% + 8px);
    height: 100%;
    top: -1px;
    left: -2px;

    border: none;
    //border-left: 2px solid var(--color-card-h1-text);
    border-radius: 2px;
    outline: none;
    resize: none;

    &::-webkit-scrollbar {
      width: 12px;
      height: 12px;
      background-color: transparent;
      //background-color: red;
    }
  }
}
</style>
